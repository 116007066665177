import { SvgIconProps } from "@mui/material";

const HartVanNederlandLogo: React.FC<SvgIconProps> = (props) => (
  <svg width="215" height="32" viewBox="0 0 269 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M55.3436 24.9669V20.6238H51.7124V24.9669H50.3527V15.0249H51.7124V19.268H55.3436V15.0249H56.6994V24.9669H55.3436Z"
        fill="white"
      />
      <path
        d="M69.2047 24.9669L68.5888 22.9673H65.0576L64.4417 24.9669H62.998L66.2773 15.0249H67.3691L70.6484 24.9669H69.2047ZM66.8532 17.2724L65.4615 21.6715H68.2129L66.8532 17.2724Z"
        fill="white"
      />
      <path
        d="M81.8863 24.9669L79.9827 20.7957H78.303V24.9669H76.9473V15.0249H80.4146C82.1102 15.0249 83.194 16.2247 83.194 17.9283C83.194 19.352 82.4141 20.2598 81.4064 20.5678L83.4699 24.9669H81.8863ZM80.3146 16.3966H78.315V19.508H80.3146C80.7307 19.5364 81.1384 19.3815 81.4306 19.0839C81.7228 18.7863 81.8703 18.3758 81.8343 17.9603C81.8711 17.5432 81.7244 17.1307 81.4326 16.8304C81.1407 16.5301 80.7326 16.3717 80.3146 16.3966Z"
        fill="white"
      />
      <path d="M93.2319 16.3966V24.9669H91.8762V16.3966H89.4127V15.0249H95.6954V16.3966H93.2319Z" fill="white" />
      <path
        d="M113.204 24.9669H112.148L109.209 15.0249H110.616L112.676 22.2594L114.735 15.0249H116.167L113.204 24.9669Z"
        fill="white"
      />
      <path
        d="M127.129 24.9669L126.513 22.9673H122.982L122.366 24.9669H120.918L124.202 15.0249H125.293L128.573 24.9669H127.129ZM124.773 17.2724L123.378 21.6715H126.129L124.773 17.2724Z"
        fill="white"
      />
      <path
        d="M140.37 24.9669L136.227 17.9723V24.9669H134.871V15.0249H136.115L140.246 21.9955V15.0249H141.606V24.9669H140.37Z"
        fill="white"
      />
      <path
        d="M162.506 24.9669L158.367 17.9723V24.9669H157.011V15.0249H158.255L162.39 21.9955V15.0249H163.749V24.9669H162.506Z"
        fill="white"
      />
      <path
        d="M171.112 24.9669V15.0249H176.831V16.3966H172.472V19.3H176.191V20.6398H172.472V23.5951H176.831V24.9509L171.112 24.9669Z"
        fill="white"
      />
      <path
        d="M189.16 24.031C188.561 24.6619 187.718 25.0031 186.849 24.9669H183.705V15.0249H186.849C187.718 14.9887 188.561 15.3298 189.16 15.9607C190.128 17.0365 190.064 18.4882 190.064 19.9279C190.064 21.3676 190.14 22.9553 189.16 24.031ZM188.241 17.0485C187.866 16.6029 187.302 16.3612 186.721 16.3966H185.077V23.5951H186.721C187.305 23.6267 187.869 23.3788 188.241 22.9273C188.708 22.3554 188.72 21.2796 188.72 19.9119C188.72 18.5442 188.708 17.6204 188.241 17.0485Z"
        fill="white"
      />
      <path
        d="M197.115 24.9669V15.0249H202.834V16.3966H198.47V19.3H202.194V20.6398H198.47V23.5951H202.834V24.9509L197.115 24.9669Z"
        fill="white"
      />
      <path
        d="M214.659 24.9669L212.776 20.7957H211.076V24.9669H209.72V15.0249H213.188C214.883 15.0249 215.967 16.2247 215.967 17.9283C215.967 19.352 215.187 20.2598 214.179 20.5678L216.243 24.9669H214.659ZM213.088 16.3966H211.088V19.508H213.088C213.504 19.5364 213.911 19.3815 214.204 19.0839C214.496 18.7863 214.643 18.3758 214.607 17.9603C214.644 17.5432 214.497 17.1307 214.206 16.8304C213.914 16.5301 213.506 16.3717 213.088 16.3966Z"
        fill="white"
      />
      <path d="M222.977 24.9669V15.0249H224.353V23.5951H228.66V24.9509L222.977 24.9669Z" fill="white" />
      <path
        d="M240.35 24.9669L239.734 22.9673H236.203L235.587 24.9669H234.151L237.431 15.0249H238.522L241.806 24.9669H240.35ZM238.002 17.2724L236.607 21.6715H239.358L238.002 17.2724Z"
        fill="white"
      />
      <path
        d="M253.591 24.9669L249.456 17.9723V24.9669H248.1V15.0249H249.344L253.479 21.9955V15.0249H254.835V24.9669H253.591Z"
        fill="white"
      />
      <path
        d="M267.656 24.0311C267.055 24.6617 266.211 25.0027 265.341 24.9669H262.201V15.025H265.341C266.211 14.9892 267.055 15.3302 267.656 15.9608C268.624 17.0365 268.56 18.4883 268.56 19.928C268.56 21.3677 268.624 22.9553 267.656 24.0311ZM266.744 17.0485C266.373 16.597 265.809 16.3491 265.225 16.3807H263.545V23.5952H265.193C265.777 23.6268 266.341 23.3788 266.712 22.9273C267.176 22.3555 267.188 21.2797 267.188 19.912C267.188 18.5442 267.188 17.6204 266.744 17.0485Z"
        fill="white"
      />
      <path
        d="M25.3737 24.8149C25.4728 24.8155 25.5717 24.8235 25.6696 24.8389C26.056 24.8987 26.4464 24.9295 26.8374 24.9309C29.0449 24.9309 29.8927 23.8071 29.7728 23.0913C29.5875 21.9678 30.1481 20.853 31.1605 20.3319C32.4602 19.564 32.8401 18.5242 32.3242 17.4524C31.8083 16.3807 29.4648 16.2527 29.5848 15.3249C29.7048 14.3971 30.4406 14.4651 30.8645 14.4051C31.2885 14.3451 31.9443 14.0051 32.3242 12.1935C32.7042 10.3819 34.3918 8.49428 33.632 6.69864C32.8721 4.90301 31.5924 4.56708 31.0605 3.66327C30.7489 3.16271 30.1794 2.88337 29.5928 2.94341C28.9104 2.97946 28.2369 3.11416 27.5932 3.34333C25.8256 3.90722 23.79 3.13138 21.5384 4.90701C19.2869 6.68265 19.4789 6.64666 19.1029 8.28232C18.727 9.91799 19.1029 11.3377 19.7348 11.6856C20.4695 12.0959 20.8331 12.9515 20.6186 13.7652C20.3827 14.729 20.7866 15.6968 18.1911 16.8566C17.8784 17.0156 17.5372 17.1107 17.1873 17.1365C16.0236 17.1365 17.0154 15.1369 17.3553 14.549C17.7552 13.8652 18.2991 13.3493 17.0194 11.0538C16.7148 10.3899 16.0579 9.95815 15.3277 9.94198C14.982 9.94924 14.641 10.024 14.3239 10.1619C12.9802 10.7058 12.5563 21.8116 8.52909 24.479C5.69767 26.3506 7.39332 26.6146 6.28955 27.3144C5.18577 28.0143 5.77365 28.9141 4.68988 29.9499C3.6061 30.9857 3.67408 32.1374 4.88184 32.4094C5.5737 32.5653 6.19357 33.0812 7.15737 33.0812C7.86923 33.0812 8.75705 32.8013 10.0288 31.8815C12.0964 30.3618 13.1242 29.9939 13.944 29.9939C14.2843 30.0039 14.6223 30.0522 14.9518 30.1378C16.0955 30.3978 16.0836 31.5536 18.3991 32.0814C20.7146 32.6093 20.3627 32.5094 20.9266 33.9251C21.4712 35.095 21.493 36.4413 20.9866 37.6283C20.4307 38.928 20.9426 39.6279 21.8104 39.9238C21.9676 39.9762 22.1326 40.0019 22.2983 39.9999C23.3061 39.9999 24.8418 39.2 24.5378 36.6405C24.1779 33.5931 25.1457 33.5252 25.5656 31.9935C25.9855 30.4618 26.1495 27.8903 24.8618 26.7945C24.0819 26.1347 24.1499 24.8149 25.3737 24.8149Z"
        fill="white"
      />
      <path
        d="M10.8887 34.313C9.87852 35.018 8.70358 35.4505 7.47742 35.5687C10.6182 38.1084 14.4591 39.6291 18.4872 39.9278C18.1501 38.9466 18.1814 37.8763 18.5752 36.9165C15.8387 36.6899 13.1996 35.796 10.8887 34.313Z"
        fill="white"
      />
      <path
        d="M36.2915 8.29834C36.1253 9.3487 35.7947 10.3663 35.3117 11.3137C35.2117 11.5337 35.1118 11.7536 35.0238 11.9656C37.2167 16.1297 37.5939 21.0143 36.0661 25.4658C34.5384 29.9172 31.2413 33.5408 26.9534 35.4808C26.9615 35.7657 26.9842 36.05 27.0214 36.3326C27.1275 37.1424 27.0869 37.9647 26.9014 38.7601C32.7526 36.5816 37.2595 31.8083 39.0987 25.8418C40.9379 19.8754 39.9007 13.393 36.2915 8.29834Z"
        fill="white"
      />
      <path
        d="M2.99826 19.9199C2.99859 13.0688 7.09158 6.88056 13.3961 4.19914C14.1751 2.33645 15.6417 0.845474 17.4913 0.0359926L17.5953 0C11.1029 0.810727 5.41239 4.73313 2.34458 10.5121C-0.723221 16.2911 -0.783728 23.2022 2.18242 29.0341C2.40683 28.7026 2.667 28.3968 2.95827 28.1222V28.0982C2.95827 28.0503 3.01026 27.9383 3.03425 27.8543C3.20574 27.1267 3.55785 26.4542 4.05804 25.8987C3.34987 23.9852 2.99092 21.9602 2.99826 19.9199Z"
        fill="white"
      />
      <path
        d="M15.1358 6.99863C15.0919 7.26067 15.1541 7.52941 15.3087 7.7455C15.4633 7.9616 15.6975 8.10729 15.9597 8.1504C16.0141 8.15651 16.0691 8.15651 16.1236 8.1504C16.6129 8.14887 17.0291 7.79352 17.1074 7.31057C17.1074 7.31057 17.3194 5.61092 18.3072 4.65512C19.7068 3.5238 21.3239 2.6921 23.0582 2.21162C23.982 1.98766 24.6579 1.85969 25.5177 1.65973C25.6748 1.6308 25.8089 1.52906 25.8791 1.38552C25.9493 1.24199 25.9473 1.07367 25.8736 0.931878C25.6257 0.152038 24.6459 0.53196 24.5259 0.595947C23.7556 0.923214 22.9506 1.16203 22.1264 1.3078C20.8764 1.56666 19.6509 1.93192 18.4631 2.39958C15.7437 3.49935 15.1598 6.85466 15.1358 6.99863Z"
        fill="white"
      />
    </g>
  </svg>
);

export default HartVanNederlandLogo;
